import * as React from 'react';

import {Carousel, Skeleton} from 'antd';

import {Link} from 'react-router-dom';

import {SliderTesProps} from '@app/objects/SliderTesProps';
import {Testimonials} from '@app/objects/Testimonials';
import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';
import ModalTestimonial from '@app/components/UI/ModalTestimonial/ModalTestimonial';
import SliderButtons from '@app/components/LocalCommon/SliderButtons/SliderButtons';
import listenerForBrowser from '@app/components/Pages/listenerForBrowser';
import {getImagePath} from '@app/objects/Image';

const SliderTesContentItem: React.FC<Testimonials> = (
	{
		id,
		state,
		city,
		firstName,
		lastName,
		message,
		avatar,
		extraText,
		onHandleClick = () => {}}
	) => {

	const [maxCountLetters, setMaxCountLetters] = React.useState(360);
	const sizes = [
		[330, 190],
		[400, 232],
		[420, 280],
		[768, 570],
		[1024, 243]
	];

	React.useEffect(() => {
		let item;
		for (let i = 0; i < sizes.length; i++) {
			item = sizes[i];
			if (window.matchMedia(`screen and (max-width: ${item[0]}px)`).matches) {
				setMaxCountLetters(item[1]);
				break;
			}
		}
	}, []);

	return(
		<div className="slider-tes__item">
			<div>
				<div className="slider-tes__item-header">
					<h4 className="slider-tes__item-title">
						{firstName} {lastName}
					</h4>
					<div className="slider-tes__item-location">
						{city}, {state}
					</div>
				</div>
				<div className={`slider-tes__item-text ${message.length > maxCountLetters ? 'slider-tes__item-text__with-continue' : ''}`}>
					{message}
				</div>
			</div>
			{(message.length > maxCountLetters) &&
				<LinkMore
					linkMoreCaption="Read more"
					linkMoreLink={'#'}
					handleCLick={onHandleClick}
				/>
			}
			{extraText ? <p className="slider-tes__item-extra-text">{extraText}</p> : null}
		</div>
	);
};

const SliderTesCarousel: React.FC<SliderTesProps> = ({sliderTesItemContent, title, location}) => {
	const slider = React.useRef<any>();
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const [slidesToShow, setSlidesToShow] = React.useState(3);
	const [showDots, setShowDots] = React.useState(false);
	const currentImage = sliderTesItemContent[currentIndex];

	const [testimonial, setTestimonial] = React.useState<Testimonials>();
	const [modalVisible, setModalVisible] = React.useState(false);

	const [showLabel, setShowLabel] = React.useState(false);

	React.useEffect(() => {
		const mql = window.matchMedia('screen and (max-width: 768px)');

		const listenerFunc = () => {
			if (mql.matches) {
				if (sliderTesItemContent.length > 6) {
					setShowDots(false);
					setShowLabel(true);
				} else {
					setShowDots(true);
					setShowLabel(false);
				}
				setSlidesToShow(1);
			} else {
				setSlidesToShow(3);
				setShowDots(false);
				setShowLabel(false);
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}, [sliderTesItemContent]);

	return(
		<>
			<header className = "slider-tes__header">
				{title && (
				<div className="slider-tes__title">
					{location && (
						<div className="slider-tes-location">
							<img className="slider-tes-location__icon" src={require('@app/images/pin_circle.svg')} alt="" />
							{location}
						</div>
					)}
					<h2 className="slider-tes__title-text">{title}</h2>
				</div>
				)}
				<div className="slider-tes__nav">
					<SliderButtons slider={slider}/>
					<Link to="/veterinarian-testimonials" type="button" className="btn btn-md btn-primary slider-tes__all-tes">
						View all testimonials
					</Link>

				</div>
			</header>

			<div className="slider-tes-body">
				{(!sliderTesItemContent.length) ? <Skeleton title={false} paragraph={{ rows: 1, className: 'slider-tes-skeleton' }} active/> :
				<div className="slider-tes-body__left">
					<img src={getImagePath(currentImage.avatar, 500)} alt="tesImage"/>
				</div>}
				{(!sliderTesItemContent.length) ? <Skeleton title={false} paragraph={{ rows: 1, className: 'slider-tes-skeleton' }} active/> :
					<div className="slider-tes-body__right">
						<Carousel
							ref={slider}
							slidesToShow={slidesToShow}
							afterChange={setCurrentIndex}
							dots={showDots}
							infinite
						>
							{sliderTesItemContent.map(item => (
								<SliderTesContentItem
									firstName = {item.firstName}
									lastName = {item.lastName}
									city = {item.city}
									state = {item.state}
									id = {item.id}
									key = {item.id}
									message = {item.message}
									avatar = {item.avatar}
									originalAvatar= {item.originalAvatar}
									extraText={item.extraText && item.extraText }
									onHandleClick = {(e) => {
										e.preventDefault();
										setModalVisible(true);
										setTestimonial(item);
									}}
								/>
							))}
						</Carousel>

						{showLabel &&
							<div className="slider-tes-pagination" >
								<label className="slider-tes-label" >{currentIndex + 1} / {sliderTesItemContent.length}</label>
							</div>
						}
					</div>
				}

			</div>

			{testimonial &&
				<ModalTestimonial
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
					id={testimonial.id}
					authorFirstName={testimonial.firstName}
					authorLastName={testimonial.lastName}
					vetAvatar={testimonial.avatar}
					location={`${testimonial.city}, ${testimonial.state}`}
					text={testimonial.message}
					getContainer=".slider-tes"
					extraText={testimonial.extraText}
				/>
			}
		</>
	);
};

export default SliderTesCarousel;