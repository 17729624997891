import * as React from 'react';

import SliderTesCarousel from '@app/components/UI/Slider/SliderTesCarousel';
import {SliderTesProps} from '@app/objects/SliderTesProps';

const SliderTes: React.FC<SliderTesProps> = (
	{title, 
	location, 
	sliderTesItemContent}) => (
	<section className = "slider-tes">
		<div className = "slider-tes__wrapper">
			<div className = "slider-tes-content">
				<div className="slider-tes-carousel">
					<SliderTesCarousel 
						sliderTesItemContent={sliderTesItemContent} 
						title = {title} 
						location = {location}/>
				</div>
			</div>
			
		</div>
			
	</section>
);

export default SliderTes;