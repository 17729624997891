import * as React from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';

import {actionCreators} from '@common/react/store/PageItem';

import MustheadHero from '@app/components/UI/MustHead/MustheadHero';
import LinkBlockIcons from '@app/components/UI/LinkBlock/LinkBlockIcons';
import LinkBlockRich from '@app/components/UI/LinkBlock/LinkBlockRich';
import SliderVet from '@app/components/UI/Slider/SliderVet';
import {
	homeLinkItems,
	homeLinkRichItems,
	homeLinkRichTitle, homeSliderTesTitle,
	homeSliderVetTitle, ImageCapture, blockRichImgCaptures, blockRichText,
	blockRichTitle
} from '@app/components/Pages/Home/homeContent';
import {Veterinarian} from '@app/objects/Veterinarian';
import SliderTes from '@app/components/UI/Slider/SliderTes';
import {Testimonials} from '@app/objects/Testimonials';
import itsTime from '@app/content/BlockRichResources/How-will-I-know-its-time.jpg';
import blog from '@app/content/BlockRichResources/Roger-UngarDumont-NJ.jpg';
import bohdi from '@app/content/BlockRichResources/Bohdi5-fixed.jpg';
import BlockRich from '@app/components/UI/LinkBlock/BlockRich';
import {ApplicationState} from '@app/store';
import {HomePageContent} from '@app/objects/pageContent/HomePageContent';

const imgHome = require('@app/content/homepage.png');
const imgHomeWebp = require('@app/content/homepage.webp');

interface PageProps extends HomePageContent {
	veterinarians: Veterinarian[];
	testimonials: Testimonials[];
	stateName: string;
}

type Props = PageProps & typeof actionCreators;

const Home: React.FC<Props> = (props) => {
	React.useEffect(() => {
		props.loadPage('homePage', 'home');
	}, []);

	return (
		<div className="home-page">
			{props.title && <Helmet>
				<title>{props.title} | Lap of Love</title>
				<meta name="description" property="og:description" content={props.description || undefined} />
			</Helmet>}
			<MustheadHero
				title={props.header}
				imageSrc={imgHome}
				imageSrcWebp={imgHomeWebp}
				imageAlt="In-home euthanasia"
				imageWidth={624}
				imageHeight={554}
			/>
			<LinkBlockIcons items={homeLinkItems}/>
			<SliderVet
				subtitle="Our veterinarians"
				title={homeSliderVetTitle}
				location={'Viewing Lap of Love Vets'}
				items={props.veterinarians}
			/>
			<LinkBlockRich
				title={homeLinkRichTitle}
				subtitle="Our services"
				images={[
					{title: 'Dr. Dani & Fiona', src: require('@app/content/daniAndFiona.jpg'), alt: 'Veterinary pet hospice'},
					{title: 'Dr. Mary & Serissa', src: require('@app/content/maryAndSerissa.jpg'), alt: 'Pet quality of life'},
					{title: 'Weezy, Port Charlotte, FL', src: require('@app/content/street_dog.png'), alt: 'Pet loss support'},
				]}
				links={homeLinkRichItems}
				video="/forever-home.mp4"
			/>
			<SliderTes
				title={homeSliderTesTitle}
				location={'Viewing Lap of Love Testimonials'}
				sliderTesItemContent = {props.testimonials}
			/>

			<BlockRich
				title={blockRichTitle}
				text={blockRichText}
				blockClassName="link-block-rich_modern"
				images={[
					{title: <ImageCapture
							name={blockRichImgCaptures[2].name}
							town={blockRichImgCaptures[2].town}
							title={blockRichImgCaptures[2].title}/>,
						src: itsTime,
						link: '/how-will-i-know-it-is-time',
						alt: 'When to put a dog down'
					},
					{title: <ImageCapture
							name={blockRichImgCaptures[1].name}
							town={blockRichImgCaptures[1].town}
							title={blockRichImgCaptures[1].title}/>,
						src: blog,
						style: {objectPosition: 'left top'},
						link: '/blog',
						alt: 'Home pet euthanasia'
					},

					{title: <ImageCapture
							name={blockRichImgCaptures[0].name}
							town={blockRichImgCaptures[0].town}
							title={blockRichImgCaptures[0].title}/>,
						src: bohdi,
						link: '/resource-center/symptom-management',
						alt: 'Cat euthanasia'
					},
				]}

				subtitle="Helpful resources"

			/>
		</div>
	);
};

export default connect<PageProps, any, any, ApplicationState>(
	(state) => {
		return {
			title: null,
			description: null,
			header: 'Lap of Love Veterinary Hospice & In-Home Euthanasia',
			veterinarians: state.homePage.page?.veterinarians?.list || [],
			testimonials: state.homePage.page?.testimonials?.list || [],
			stateName: state.homePage.page?.stateName || 'our',
			...state.homePage.page?.content
		};
	},
	actionCreators
)(Home);