import * as React from 'react';
import {Link} from 'react-router-dom';
import LazyLoad from 'react-lazy-load';

import {Image} from '@app/objects/Image';
import {LinkBlockItemRich} from '@app/objects/LinkBlockItem';

interface LinkBlockRichProps {
	title: string | JSX.Element;
	subtitle?: string;
	images: [Image, Image, Image];
	links?: LinkBlockItemRich[];
	video?: string;
}

const LinkBlockRich: React.FC<LinkBlockRichProps> = props => (
	<section className="link-block-rich">
		<div className="link-block-rich__block-left">
			<div className="link-block-rich__wrapper">
				<header className="link-block-rich-header">
					{props.subtitle && <h6 className="link-block-rich-header__subtitle">{props.subtitle}</h6>}
					<h2 className="link-block-rich-header__title">{props.title}</h2>
				</header>
				<div className="link-block-rich-links">
					{props.links && props.links.map((linkItem: LinkBlockItemRich, index: number) => (
						<Link to={linkItem.path} className="link-item" key={linkItem.name + index}>
							<div className="link-item-header">
								<img className="link-item-header__icon" src={linkItem.icon} alt={linkItem.alt} width={40} height={40}/>
								<h5 className="link-item-header__title">{linkItem.name}</h5>
							</div>
							<div className="link-item-body">
								<p>{linkItem.text}</p>
								<span className="link-item-body__link">Learn more</span>
							</div>
						</Link>
					))}
				</div>

				<div className="link-block-rich-links link-block-rich-links_mobile">
					{props.links && props.links.map((linkItem: LinkBlockItemRich, index: number) => (
						<div className="link-item link-item_mobile" key={linkItem.name + index}>
							<div className="link-item-header">
								<img className="link-item-header__icon" src={linkItem.icon} alt="" width={40} height={40}/>
								<h5 className="link-item-header__title">{linkItem.name}</h5>
							</div>
							<div className="link-item-body">
								<p>{linkItem.text}</p>
								<Link to={linkItem.path} className="link-item-body__link">Learn more</Link>
							</div>
						</div>
					))}
				</div>

			</div>
		</div>
		<div className="link-block-rich__block-right">
			{!props.video && <>
				<div className="link-block-rich-col">
					{props.images.slice(0, 2).map((image: Image, key: number) => (
						<div className="image-wrapper" key={key}>
							<LazyLoad offset={1000}>
								<img className="image-wrapper__img" src={image.src} alt={image.alt || ''}/>
							</LazyLoad>
							<label className="image-wrapper__title">{image.title}</label>
						</div>
					))}
				</div>
				<div className="link-block-rich-col">
					<div className="image-wrapper">
						<LazyLoad offset={1000}>
							<img className="image-wrapper__img" src={props.images[2].src} alt={props.images[2].alt || ''}/>
						</LazyLoad>
						<label className="image-wrapper__title">{props.images[2].title}</label>
					</div>
				</div>
			</>}
			{props.video && <video width="100%" controls>
				<source
					src={props.video}
					type="video/mp4"
				/>
				Sorry, your browser doesn't support embedded videos.
			</video>}
		</div>
	</section>
);

export default LinkBlockRich;