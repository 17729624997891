import * as React from 'react';

import SearchField from '@app/components/Forms/SearchField/SearchField';

interface MustheadHeroProps {
	title: string;
	imageSrc: string;
	imageAlt?: string;
	imageSrcWebp?: string;
	imageWidth?: number;
	imageHeight?: number;
}

const scribbleBack = require('@app/images/scribble_back.png');
const scribbleFront = require('@app/images/scribble_front.png');

const MustheadHero: React.FC<MustheadHeroProps> = props => (
	<div className="musthead musthead_hero">
		<div className="musthead-wrapper">
			<div className="musthead-body">
				<div className="musthead-body__left">
					<h1 className="musthead-body__title">{props.title}</h1>
					<div className="musthead-body-search">
						<p className="musthead-body-search__text">
							Find a Lap of Love vet near you
						</p>
						<SearchField placeholder="Enter your 5-digit ZIP code" iconLocation={true} widthBorder="0" searchBody="/find-a-vet/search"/>
					</div>
				</div>
				<div className="musthead-body__image-flat">
					<picture>
						<source className="musthead-body__image-content" srcSet={props.imageSrcWebp} type="image/webp"/>
						<img
							className="musthead-body__image-content"
							width={props.imageWidth}
							height={props.imageHeight}
							src={props.imageSrc}
							alt={props.imageAlt || ''}/>
					</picture>
				</div>
			</div>
		</div>
	</div>
);

export default MustheadHero;