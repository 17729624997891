import * as React from 'react';
import {Link} from 'react-router-dom';

import {LinkBlockItemIcon} from '@app/objects/LinkBlockItem';
import {pushIconClick} from '@app/components/TagManager';

interface LinkBlockIconsProps {
	items: LinkBlockItemIcon[];
}

const LinkBlockIcons: React.FC<LinkBlockIconsProps> = ({ items }) => (
	<div className="link-block-icons">
		{items.map((item, index) => (
			<div className="link-block-icon-wrapper" key={item.path + index} onClick={() => pushIconClick(item.id)}>
				<div className="link-block-icon-wrapper__helper" />
				<Link className="link-block-icon" to={item.path} >
					<img className="link-block-icon__icon" src={item.src} alt={item.alt || ''} width={120} height={120}/>
					<h5 className="link-block-icon__title">{item.name}</h5>
				</Link>
			</div>
		))}
	</div>
);

export default LinkBlockIcons;